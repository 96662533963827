import React from 'react';

import {Div, FixedLayout, Panel, PanelHeader} from '@vkontakte/vkui';

import people_png from '../img/people.png';
import Button from "../ui/components/Button/Button";

const Subscribe = props => {
    const user = props.fetchedUser;
    let title = 'Привет';
    if (user !== null) {
        title += ', ' + user.first_name;
    }

    const subscribe = () => {
        props.subscribe((result) => {
            if (result) {
                skip();
            }
        });
    }

    const skip = () => {
        props.replacePanel('/');
    }

    return (
        <Panel id={props.id}>
            <PanelHeader separator={false} transparent={true} left={<p onClick={() => skip()} className="header-word">Пропустить</p>}></PanelHeader>

            <div className="image-container">
                <div className="image-background"></div>
                <img src={people_png} alt=""/>
            </div>

            <Div style={{ marginTop: '3vh' }}>
                <div className="texts">
                    <h1>{title}</h1>
                    <p>Предлагаем вам подписаться на наше сообщество, чтобы быть в курсе всех новостей!</p>
                </div>
            </Div>

            <FixedLayout vertical="bottom">
                <Div>
                    <Button onClick={() => subscribe()} text="Подписаться" mode="blue" stretched />
                </Div>
            </FixedLayout>

            <div className="background--subscribe"></div>
        </Panel>
    );
};

export default Subscribe;
