import './Switch.css';
import {useEffect, useState} from "react";

const Switch = ({ onClick = null, isChecked = false }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (isChecked) {
            setChecked(true);
        }
    }, []);

    const check = () => {
        const newValue = !checked
        setChecked(newValue);
        if (onClick !== null) {
            onClick(newValue);
        }
    }

    let classes1 = '';
    let classes2 = '';
    if (checked) {
        classes1 += 'switch-active';
        classes2 += 'switch-button--active';
        classes2 += ' switch-button--active-animation';
    }

    return (
        <div className={"switch " + classes1} onClick={check}>
            <div className={"switch-button " + classes2}></div>
        </div>
    );
}

export default Switch;