import './Ellipse.css';
import React from "react";
import SuperEllipse from "react-superellipse/index";
import brush_ellipse_png from '../../../img/brush-ellipse.png';
import tooth_ellipse_png from '../../../img/tooth-ellipse.png';
import bottle_ellipse_png from '../../../img/bottle-ellipse.png';

const Ellipse = ({ type = 'blue', text, onClick }) => {
    let img = '';
    if (type === 'blue') {
        img = brush_ellipse_png;
    } else if (type === 'orange') {
        img = tooth_ellipse_png;
    } else if (type === 'red') {
        img = bottle_ellipse_png;
    } else {
        return '';
    }

    return (
        <div onClick={onClick} className="ellipse--back">
            <div className={"ellipse-shadow ellipse-shadow-" + type}>
                <SuperEllipse className={"ellipse ellipse--" + type} r1={0.1} r2={0.4}>
                    <img src={img} alt=""/>
                    <p>{text}</p>
                </SuperEllipse>
            </div>
        </div>
    );
}

export default Ellipse;