import {ModalCard} from "@vkontakte/vkui";
import ModalCardContent from "../components/ModalCardContent/ModalCardContent";
import Button from "../components/Button/Button";

const NotAllowNotifications = (props) => {
    return (
        <ModalCard id={props.id}>
            <ModalCardContent
                title="Уведомления"
                text="Для включения уведомлений нам нужно разрешение."
                buttons={
                    <div style={{ marginTop: 21 }}>
                        <Button onClick={() => props.allowMessages((e) => {
                            if (e) {
                                props.goBack();
                            }
                        })} text="Разрешить" stretched />
                        <Button onClick={() => props.goBack()} style={{ marginTop: 7 }} text="Отмена" mode="gray" stretched />
                    </div>
                }
            />
        </ModalCard>
    );
}

export default NotAllowNotifications;