import React from "react";
import './ListItem.css';

const ListItem = ({ icon, title, text }) => {
    return (
        <div className="list-item">
            <div className="list-item--image"><img src={icon} alt=""/></div>
            <div className="list-item--text">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </div>
    );
}

export default ListItem;