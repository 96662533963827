import React from 'react';

import {Avatar, Div, FixedLayout, Panel, PanelHeader, Snackbar, Tooltip} from '@vkontakte/vkui';
import IconButton from "../ui/components/IconButton/IconButton";
import {Icon16Done, Icon28CancelOutline, Icon28MusicMicOutline, Icon28MuteOutline} from "@vkontakte/icons";
import TimerPanel from "../ui/components/TimerPanel/TimerPanel";
import Button from "../ui/components/Button/Button";
import SubscribeBanner from "../ui/components/SubscribeBanner/SubscribeBanner";

class Timer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackbar: null,
            tooltip: false,
            timer: 5,
            text: 'Приготовьтесь!',
            voice: false,

            progressDuration: null,
            realProgressDuration: null,

            blockTimer: false,

            hideClass: '',
            finish: false,

            audio: {
                start: require('../audio/start.mp3'),
                finish: require('../audio/finish.mp3'),
            },

            data: {
                brush: {
                    duration: 120,
                    timeCodes: {
                        120: {
                            text: 'Начните с наружных и внутренних поверхностей. Убедитесь, что достаете до дальних зубов.',
                            audio: require('../audio/brush/1.mp3'),
                            duration: 45,
                        },
                        75: {
                            text: 'Перейдите к жевательной поверхности',
                            audio: require('../audio/brush/2.mp3'),
                            duration: 30,
                        },
                        45: {
                            text: 'Перейдите к внутренней поверхности передних зубов',
                            audio: require('../audio/brush/3.mp3'),
                            duration: 15,
                        },
                        30: {
                            text: 'Нежно чистите зубы вдоль линии десен',
                            audio: require('../audio/brush/4.mp3'),
                            duration: 15,
                        },
                        15: {
                            text: 'Почистите язык',
                            audio: require('../audio/brush/5.mp3'),
                            duration: 15,
                        },
                    },
                },

                thread: {
                    duration: 370,
                    timeCodes: {
                        370: {
                            text: 'Приготовьтесь: возьмите кусочек нити.',
                            audio: require('../audio/thread/1.mp3'),
                            duration: 10,
                        },
                        360: {
                            text: 'Начните с нижней челюсти. Аккуратно чистите каждый зуб движениями снизу вверх. Для каждого зуба используйте новый кусочек нити.',
                            audio: require('../audio/thread/2.mp3'),
                            duration: 180,
                        },
                        180: {
                            text: 'Перейдите к верхней челюсти. Аккуратно чистите каждый зуб движениями сверху вниз. Для каждого зуба используйте новый кусочек нити.',
                            audio: require('../audio/thread/3.mp3'),
                            duration: 180,
                        },
                    },
                },

                rinse: {
                    duration: 40,
                    timeCodes: {
                        40: {
                            text: 'Налейте 20 мл ополаскивателя в стакан и наберите в рот.',
                            audio: require('../audio/rinse/1.mp3'),
                            duration: 10,
                        },
                        30: {
                            text: 'Полощите рот',
                            audio: require('../audio/rinse/2.mp3'),
                            duration: 30,
                        },
                    },
                }
            },
        };

        /*let sBrowser, sUsrAg = navigator.userAgent;
        if (sUsrAg.indexOf("Safari") > -1) {
            console.log('safari')
        }*/
    }

    getBrowserId = () => {
        let
            aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
            sUsrAg = navigator.userAgent, nIdx = aKeys.length - 1;

        for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

        return nIdx
    }

    openBaseWithAction = (text, icon = null) => {
        this.setState({
            snackbar: (
                <Snackbar
                    onClose={() => this.setState({ snackbar: null })}
                    onActionClick={() => this.setState({ text: "Добавляем метку." })}
                    before={
                        <Avatar size={24} style={{ background: "var(--accent)" }}>
                            {icon === null ? <Icon16Done fill="#fff" width={14} height={14} /> : icon}
                        </Avatar>
                    }
                >
                    {text}
                </Snackbar>
            ),
        });
    }

    componentDidMount() {
        const premium = this.props.data.premium;

        this.props.storageGet(['voice'], (response) => {
            const data = response.keys[0].value;
            if (data === '1' && premium && this.getBrowserId() !== 2) {
                this.setState({ voice: true });
            }
        });

        this.showTooltip();
        this.start();

        this.props.router.on('update', (nextRoute, oldRoute) => {
            if (nextRoute.isModal() && !oldRoute.isModal()) {
                this.stopStart();
            }

            if (nextRoute.getPageId() === '/timer' && oldRoute.isModal()) {
                this.stopStart();
            }
        });

        this.props.router.onLeavePage('/timer', (r) => {
            if (r.getPageId() === '/') {
                this.props.ads((result) => {
                    if (!result && !this.props.data.subscribe) {
                        this.props.showSubscribeModal();
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.timerId);

        this.pauseVoice();
        this.setState({ snackbar: null });
    }

    showTooltip = () => {
        this.props.showTimerTooltip((result) => {
            if (result) {
                this.setState({ tooltip: true });
                setTimeout(() => {
                    this.setState({ tooltip: false });
                }, 4000);
            }
        });
    }

    start = () => {
        this.timerId = setInterval(() => {
            const next = this.state.timer - 1;
            this.setState({ timer: next });

            if (next === 3 && this.state.voice) {
                this.voiceAction(this.state.audio.start);
            }

            if (next === 0) {
                clearInterval(this.timerId);
                this.setState({ timer: this.state.data[this.props.timerMode].duration });
                this.updateData(false);
                this.startMainTimer();
            }
        }, 1000);
    }

    startMainTimer = () => {
        this.timerId = setInterval(() => {
            if (this.state.blockTimer) {
                return;
            }

            const time = this.state.timer;
            if (time === 0) {
                clearInterval(this.timerId);
                this.goFinish();
                return;
            }

            this.updateData();

            this.setState({ timer: time - 1, realProgressDuration: this.state.realProgressDuration - 1 });
        }, 1000);
    }

    updateData = (playAudio = true) => {
        const time = this.state.timer;
        if (typeof(this.state.data[this.props.timerMode].timeCodes[time]) !== 'undefined') {
            const data = this.state.data[this.props.timerMode].timeCodes[time];
            this.activeAudio = data.audio;

            if (playAudio && this.state.voice) {
                this.voiceAction(data.audio);
            }
            this.setState({ text: data.text, realProgressDuration: data.duration, progressDuration: data.duration });
        }
    }

    goFinish = () => {
        if (this.state.voice) {
            this.voiceAction(this.state.audio.finish);
        }
        this.props.serverPost('cleaning', { mode: this.props.timerMode }, () => {});

        this.setState({ hideClass: 'hide-animation' });
        setTimeout(() => {
            this.setState({ finish: true, hideClass: '' });
        }, 500);
    }

    stopStart = () => {
        if (this.state.progressDuration === null) {
            return;
        }

        this.setState({ blockTimer: !this.state.blockTimer });
    }

    voice = () => {
        const premium = this.props.data.premium;
        if (!premium) {
            this.props.goModal('noSubscribe');
            return;
        }

        if (this.state.voice) {
            this.openBaseWithAction('Озвучка выключена');
            this.disableVoice();
            return;
        }

        this.openBaseWithAction('Озвучка включена');
        this.enableVoice();
    }

    enableVoice = () => {
        this.props.storageSet('voice', '1', () => {});
        this.setState({ voice: true });

        if (typeof(this.activeAudio) !== 'undefined') {
            this.voiceAction(this.activeAudio);
        }
        //this.voiceAction();
    }

    voiceAction = (url) => {
        this.pauseVoice();

        this.audio = new Audio(url);

        this.audio.volume = 0.3;
        this.audio.loop = false;

        const playPromise = this.audio.play();
        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    console.log('audio played');
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ voice: false });
                });
        }
    }

    pauseVoice = () => {
        if (typeof(this.audio) !== 'undefined') {
            if (!this.audio.paused) {
                this.audio.pause();
            }
        }
    }

    disableVoice = () => {
        this.props.storageSet('voice', '0', () => {});
        this.setState({ voice: false });
        this.pauseVoice();
    }

    render() {
        const hideClass = this.state.hideClass;
        const finish = this.state.finish;

        const realProgressDuration = this.state.realProgressDuration;
        let percent = null
        if (realProgressDuration !== null) {
            const progressDuration = this.state.progressDuration;
            percent = ((progressDuration - realProgressDuration) / progressDuration) * 100;
        }

        let background = 'var(--gradient-blue-forward)';
        if (this.props.timerMode === 'thread')
            background = 'var(--gradient-orange)';
        else if (this.props.timerMode === 'rinse')
            background = 'var(--gradient-red)';

        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    separator={false}
                    transparent={true}
                    left={
                        <div style={{ display: 'flex' }}>
                            <IconButton onClick={() => this.props.goBack()} mode={2} icon={<Icon28CancelOutline />} />
                            {!finish && (
                                <Tooltip isShown={this.state.tooltip} text="Озвучим вам каждое действие">
                                    <div className={hideClass}>
                                        <IconButton onClick={() => this.voice()} mode={3} icon={this.state.voice ? <Icon28MuteOutline /> : <Icon28MusicMicOutline />} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    }
                > </PanelHeader>

                {!finish && (
                    <div onClick={() => this.stopStart()}>
                        <div className={"timer " + hideClass}>
                            <h1 style={{ opacity: this.state.blockTimer ? .5 : 1 }}>{this.state.timer}</h1>
                        </div>

                        <div className="timer-bottom">
                            <FixedLayout vertical="bottom">
                                <div className={hideClass}>
                                    <TimerPanel progress={percent} text={this.state.text} />
                                </div>
                            </FixedLayout>
                        </div>
                    </div>
                )}

                {finish && (
                    <div>
                        <Div className="timer-finish">
                            <h1>Готово!</h1>
                            <p>Не забывайте чистить зубы не реже 2 раз в день.</p>
                            {!this.props.data.notifications.cleaning ? (
                                <Button onClick={() => this.props.openNotificationsModal()} text="Включить напоминания" stretched />
                            ) : !this.props.data.subscribe ? (
                                <Button onClick={() => this.props.subscribe(() => {})} text="Подписаться на сообщество" stretched />
                            ) : ''}
                        </Div>

                        {!this.props.data.premium && (
                            <FixedLayout vertical="bottom">
                                <Div>
                                    <SubscribeBanner onClick={() => this.props.replacePanel('/premium')} />
                                </Div>
                            </FixedLayout>
                        )}
                    </div>
                )}

                <div style={{ background: background }} className="background--timer" onClick={() => this.stopStart()}>
                    <div className="circles"></div>
                </div>

                {this.state.snackbar}
            </Panel>
        );
    }

}

export default Timer;
