import './OnbordingPanel.css';

const OnbordingPanel = ({ title, text, button }) => {
    return (
        <div className="onbording-panel">
            <h1>{title}</h1>
            <p>{text}</p>
            {button}
        </div>
    );
}

export default OnbordingPanel;