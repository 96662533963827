import './StatisticCard.css';

const StatisticCard = ({ day, count, animation = null }) => {
    let height = 80;
    let padding = 22;
    if (count === 2) {
        height = 120;
        padding = 40;
    } else if (count >= 3) {
        height = 160;
        padding = 60;
    }

    let warning = '';
    if (count < 2) {
        warning = ' statistic-card-warning';
    }

    let animationClass = '';
    if (animation !== null) {
        if (animation === 'down') {
            animationClass = 'animation-down';
        }
        else if (animation === 'up') {
            animationClass = 'animation-up';
        }
    }

    let word = '';
    if (count === 0 || count === 1 || count >= 5)
        word = 'раз';
    else if (count >= 2 && count <= 4)
        word = 'раза';

    return (
        <div style={{ height: height, padding: String(padding) + 'px 0' }} className={"statistic-card" + warning + ' ' + animationClass}>
            <p>{day}<br /><span>{String(count) + ' ' + word}</span></p>
        </div>
    );
}

export default StatisticCard;