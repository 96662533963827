import React, {useEffect} from 'react';

import {Div, HorizontalScroll, Panel, PanelHeader} from '@vkontakte/vkui';
import StatisticsCardGrid from "../ui/components/StatisticsCardGrid/StatisticsCardGrid";
import ItemCard from "../ui/components/ItemCard/ItemCard";
import toothbrush_png from '../img/toothbrush.png';
import lock_png from '../img/lock.png';
import IconButton from "../ui/components/IconButton/IconButton";
import {Icon28Notifications} from "@vkontakte/icons";

const DAYS = {
    1: 'Пн.',
    2: 'Вт.',
    3: 'Ср.',
    4: 'Чт.',
    5: 'Пт.',
    6: 'Сб.',
    0: 'Вс.',
};

const ACHIEVEMENTS = {
    1: 'Первая чистка щеткой',
    2: 'Первая чистка нитью',
    3: 'Первое ополаскивание',
    4: '5 чисток щеткой',
    //5: '2 чистки щеткой за день',
    //6: '14 чисток щеткой за неделю',
    //7: '60 чисток щеткой за месяц',
    8: '100 чисток щеткой',
    9: '10 полосканий',
    10: '10 чисток нитью',
    //11: '2 чистки нитью за день',
};

const convertDay = (array) => {
    const na = array;

    for (let i in array) {
        const data = array[i];
        na[i].day = DAYS[array[i].number];
    }

    return na;
}

const getAchievements = (achievements) => {
    if (achievements === null) {
        return (
            <p>empty</p>
        );
    }

    const el = [];
    for (let i in ACHIEVEMENTS) {
        const text = ACHIEVEMENTS[i];
        i = Number(i);

        if (achievements.includes(i)) {
            el.push(<ItemCard key={i} icon={toothbrush_png} text={text} />);
            continue;
        }

        el.push(<ItemCard key={i} icon={lock_png} text={text} />);
    }

    return el;
}

const Statistics = props => {
    useEffect(() => {
        if (!props.data.subscribe)
            props.showSubscribeModal();
    }, []);

    let statistics = props.data === null ? null : props.data.statistics;
    if (statistics !== null && statistics !== 'empty') {
        statistics = convertDay(statistics);
    }

    let achievements = props.data === null ? null : props.data.achievements;
    achievements = getAchievements(achievements);

    return (
        <Panel id={props.id}>
            <PanelHeader left={<IconButton onClick={() => props.openNotificationsModal()} icon={<Icon28Notifications />} />} separator={false}></PanelHeader>
            <Div style={{ marginTop: 21 }}>
                <h1>Статистика</h1>
                <div style={{ marginTop: 21 }}>
                    {statistics !== 'empty' ? (
                        <StatisticsCardGrid
                            /*data={[
                                { day: 'Пн.', count: 2 },
                                { day: 'Вт.', count: 1 },
                                { day: 'Ср.', count: 2 },
                                { day: 'Чт.', count: 3 },
                                { day: 'Пт.', count: 0 },
                                { day: 'Сб.', count: 2 },
                                { day: 'Вс.', count: 2 },
                            ]}*/
                            data={statistics}
                        />
                    ) : (
                        <p>Статистики за эту неделю еще нет</p>
                    )}
                </div>
            </Div>

            <Div style={{ marginTop: 35 }}>
                <h1>Достижения</h1>
            </Div>
            {/*<div style={{ marginTop: 21 }} className="slider">
                <div className="slider-content">
                    {achievements}
                </div>
            </div>*/}

            <Div style={{ marginTop: 21 }}>
                <HorizontalScroll
                    showArrows
                    getScrollToLeft={(i) => i - 120}
                    getScrollToRight={(i) => i + 120}
                >
                    <div style={{ display: "flex" }}>
                        {achievements}
                    </div>
                </HorizontalScroll>
            </Div>
        </Panel>
    );
};

export default Statistics;
