import './Button.css';

const Button = ({ text, mode = 'default', stretched = false, onClick, style = {} }) => {
    let classes = 'button--' + mode + ' ';
    if (stretched) {
        classes += 'button--stretched';
    }

    return (
        <button style={style} onClick={onClick} className={classes}>{text}</button>
    );
}

export default Button;