import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import {
	View,
	ScreenSpinner,
	AdaptivityProvider,
	AppRoot,
	Epic,
	Tabbar,
	TabbarItem,
	ModalRoot,
	IOS, usePlatform
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import {useLocation, useRouter} from "@happysanta/router";
import {
	PANEL_HOME,
	PANEL_ONBORDING,
	PANEL_PREMIUM,
	PANEL_STATISTICS, PANEL_SUBSCRIBE, PANEL_TIMER,
	VIEW_MAIN,
	VIEW_STATISTICS
} from "./routes";
import {Icon28HomeOutline, Icon28StatisticsOutline} from "@vkontakte/icons";
import Statistics from "./panels/Statistics";
import Premium from "./panels/Premium";
import Onbording from "./panels/Onbording";
import Subscribe from "./panels/Subscribe";
import Timer from "./panels/Timer";
import NoSubscribeModal from "./ui/modals/NoSubscribeModal";
import NotAllowNotifications from "./ui/modals/NotAllowNotifications";
import NotificationsModal from "./ui/modals/NotificationsModal";
import JoinGroupModal from "./ui/modals/JoinGroupModal";

let lastTimeForAds = 0;

const App = () => {

	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<div className="first_loading1"><ScreenSpinner size='large' /></div>);
	const [firstLoading, setFirstLoading] = useState(true);
	//const [popout, setPopout] = useState(<div className="first_loading"><ScreenSpinner size='large' /></div>);
	const [startParams, setStartParams] = useState(null);

	const [timerMode, setTimerMode] = useState(null);
	const [data, setData] = useState(null);

	const router = useRouter();
	const location = useLocation();
	const platform = usePlatform();

	const editData = (key, value) => {
		const d = data;
		d[key] = value;
		setData(d);
	}

	const getStartParams = () => {
		const p = window.location.search.slice(1);
		const queryString = require('query-string');
		return queryString.parse(p);
	}

	const initStartParams = () => {
		setStartParams(getStartParams());
	}

	const init = (showSubscribe = true) => {
		initStartParams();

		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');

			await serverPost('init', {}, (response) => {
				//console.log(response);
				setData(response);
				goToOnbording(response.subscribe, showSubscribe);
				setPopout(null);
				setFirstLoading(false);
			});

			setUser(user);
		}
		fetchData().then();
	}

	const goToOnbording = (subscribe, showSubscribe) => {
		storageGet(['onbording'], (result) => {
			if (result.keys[0].value === '') {
				replacePanel('/onbording');
			} else if (!subscribe && showSubscribe) {
				storageGet(['subscribePage'], (result) => {
					if (result.keys[0].value === '') {
						replacePanel('/subscribe');
						storageSet('subscribePage', 'ok', () => {});
					}
				});
			}
			setPopout(null);
		});
	}

	const showSubscribeModal = () => {
		storageGet(['subscribe'], (result) => {
			const data = result.keys[0].value;
			if (data !== '2') {
				goModal('joinGroup');
				storageSet('subscribe', data === '' ? '1' : '2', () => {});
			}
		});
	}

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppViewRestore') {
				console.log('VKWebAppViewRestore');
				init(false);
			}
		});

		init();
	}, []);

	const allowMessages = (callback) => {
		bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": 211060147})
			.then(() => {
				editData('allowMessages', true);
				callback(true);
			})
			.catch(() => {
				callback(false);
			});
	}

	const subscribe = (callback) => {
		bridge.send("VKWebAppJoinGroup", {"group_id": 211060147})
			.then(() => {
				editData('subscribe', true);
				callback(true);
			})
			.catch(() => {
				callback(false);
			});
	}

	const showTimerTooltip = (callback) => {
		storageGet(['timerTooltip'], (result) => {
			storageSet('timerTooltip', 'ok', () => {});
			callback(result.keys[0].value === '');
		});
	}

	const storageGet = (keys, callback) => {
		bridge.send("VKWebAppStorageGet", {"keys": keys}).then(response => {
			callback(response);
		});
	}

	const storageSet = (key, value, callback) => {
		bridge.send("VKWebAppStorageSet", {
			key: key,
			value: value
		}).then(response => {
			callback(response);
		});
	}

	const goPanel = (pageId) => {
		if (pageId === location.getPageId()) {
			return;
		}
		router.pushPage(pageId);
	}

	const goModal = (id) => {
		router.pushModal(id);
	}

	const go = e => {
		goPanel(e.currentTarget.dataset.to);
	};

	const goBack = () => {
		router.popPage();
	}

	const replacePanel = (pageId) => {
		router.replacePage(pageId);
	}

	const clearStorage = () => {
		storageSet('onbording', '', () => {});
		storageSet('timerTooltip', '', () => {});
		storageSet('subscribe', '', () => {});
		storageSet('voice', '', () => {});
		storageSet('subscribePage', '', () => {});
	}

	const openLink = (url) => {
		if (platform === IOS) {
			const link = document.createElement('a');
			link.href = url;
			link.target = '_blank';
			link.click();
			return;
		}

		window.open(url,'_blank')
	};

	const getAccessTokenSettings = (callback) => {
		bridge.send("VKWebAppGetLaunchParams").then(e => {
			callback(e.vk_access_token_settings);
		}).catch(e => {
			callback(null);
		});
	}

	const openNotificationsModal = () => {
		if (!data.allowMessages) {
			goModal('notAllowNotifications');
			return;
		}

		goModal('notifications');
	}

	const getUnixTime = () => {
		return +Date.now() / 1000;
	}

	const ads = (callback, type = 'interstitial') => {
		if (lastTimeForAds !== 0 && getUnixTime() - lastTimeForAds < 3) {
			return;
		}

		console.log('ads');
		lastTimeForAds = getUnixTime();

		/*if (data === null || data.premium) {
			callback(false);
			return;
		}*/

		setPopout(<ScreenSpinner size='large' />);
		bridge.send("VKWebAppShowNativeAds", { ad_format: type })
			.then(data => {
				const result = data.result;
				console.log(result);
				setPopout(null);
				callback(true);
			})
			.catch(error => {
				console.log(error);
				setPopout(null);
				callback(false);
			});
	}

	const http_build_query = (formdata, numeric_prefix = '', arg_separator = '&') => { // &amp;
		let key, use_val, use_key, i = 0, tmp_arr = [];

		if (!arg_separator) {
			arg_separator = '&';
		}

		for (key in formdata) {
			use_key = key;
			use_val = (formdata[key].toString());
			use_val = use_val.replace(/%20/g, '+');

			if (numeric_prefix && !isNaN(key)) {
				use_key = numeric_prefix + i;
			}
			tmp_arr[i] = use_key + '=' + use_val;
			i++;
		}

		return tmp_arr.join(arg_separator);
	}

	const serverPost = (method, params, callback) => {
		let sp = startParams;
		if (sp === null) {
			sp = getStartParams();
		}

		params.params = JSON.stringify(sp);
		fetch("https://teeth.apps-cdn.ru/api/" + method, {
			method: 'post',
			headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
			body: http_build_query(params)
		}).then((response) => response.json()).then((response) => {
			if (response.status !== 'ok') {
				console.log(response.response);
				return;
			}

			callback(response.response);
		}).catch((error) => {
			console.error(method);
			console.log(error);
		});
	}

	const pageId = location.getPageId();

	const modal = (
		<ModalRoot activeModal={location.getModalId()} onClose={() => goBack()}>
			<JoinGroupModal id="joinGroup" goBack={goBack} subscribe={subscribe} />
			<NoSubscribeModal id="noSubscribe" goPanel={goPanel} goBack={goBack} />
			<NotAllowNotifications id="notAllowNotifications" goBack={goBack} allowMessages={allowMessages} />
			<NotificationsModal id="notifications" editData={editData} ads={ads} goBack={goBack} data={data} goModal={goModal} serverPost={serverPost} />
		</ModalRoot>
	);

	return (
		<AdaptivityProvider>
			<AppRoot>
				<Epic
					activeStory={location.getViewId()}
					tabbar={
						(pageId !== '/onbording' && pageId !== '/premium' && pageId !== '/subscribe' && pageId !== '/timer' && !firstLoading) && (
							<Tabbar>
								<TabbarItem
									onClick={go}
									selected={location.getViewId() === VIEW_MAIN}
									data-to='/'
								><Icon28HomeOutline /></TabbarItem>
								<TabbarItem
									onClick={go}
									selected={location.getViewId() === VIEW_STATISTICS}
									data-to='/statistics'
								><Icon28StatisticsOutline /></TabbarItem>
							</Tabbar>
						)
					}
				>

					<View
						id={VIEW_MAIN}
						history={location.getViewHistory(VIEW_MAIN)}
						activePanel={location.getViewActivePanel(VIEW_MAIN)}
						popout={popout}
						modal={modal}
					>
						<Home id={PANEL_HOME} showSubscribeModal={showSubscribeModal} firstLoading={firstLoading} editData={editData} setTimerMode={setTimerMode} data={data} clearStorage={clearStorage} openNotificationsModal={openNotificationsModal} goModal={goModal} fetchedUser={fetchedUser} go={go} goPanel={goPanel} subscribe={subscribe} />
						<Premium id={PANEL_PREMIUM} goBack={goBack} openLink={openLink} />
						<Onbording id={PANEL_ONBORDING} allowMessages={allowMessages} storageSet={storageSet} replacePanel={replacePanel} />
						<Subscribe id={PANEL_SUBSCRIBE} fetchedUser={fetchedUser} replacePanel={replacePanel} subscribe={subscribe} />
						<Timer id={PANEL_TIMER} platform={platform} showSubscribeModal={showSubscribeModal} ads={ads} timerMode={timerMode} data={data} subscribe={subscribe} router={router} replacePanel={replacePanel} openNotificationsModal={openNotificationsModal} goBack={goBack} showTimerTooltip={showTimerTooltip} goModal={goModal} serverPost={serverPost} storageSet={storageSet} storageGet={storageGet} />
					</View>

					<View
						id={VIEW_STATISTICS}
						history={location.getViewHistory(VIEW_STATISTICS)}
						activePanel={location.getViewActivePanel(VIEW_STATISTICS)}
						popout={popout}
						modal={modal}
					>
						<Statistics id={PANEL_STATISTICS} showSubscribeModal={showSubscribeModal} data={data} openNotificationsModal={openNotificationsModal} />
					</View>

				</Epic>
			</AppRoot>
		</AdaptivityProvider>
	);

}

export default App;
