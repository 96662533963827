import React from 'react';

import {Div, FixedLayout, Panel, PanelHeader} from '@vkontakte/vkui';
import ListItem from "../ui/components/ListItem/ListItem";
import Button from "../ui/components/Button/Button";

import notifications_png from '../img/notifications.png';
import new_png from '../img/new.png';
import ads_png from '../img/ads.png';
import support_png from '../img/support.png';
import voice_png from '../img/voice.png';
import IconButton from "../ui/components/IconButton/IconButton";
import {Icon28CancelOutline} from "@vkontakte/icons";

const Premium = props => (
    <Panel id={props.id}>
        <PanelHeader left={<IconButton onClick={() => props.goBack()} mode={2} icon={<Icon28CancelOutline />} />} separator={false} transparent={true}></PanelHeader>
        <Div>
            <div className="premium">
                <div className="premium-title">
                    <h1>Premium-подписка со скидкой 50%</h1>
                    <p>Подписка дает:</p>
                </div>
                <div className="list">
                    <ListItem icon={notifications_png} title="Напоминания" text="О чистке зубов, замене щетки и посещении стоматолога" />
                    <ListItem icon={new_png} title="Новые функции" text="Открывает доступ к чистке нитью и полосканию" />
                    <ListItem icon={voice_png} title="Озвучку действий" text="Открывает доступ к озвучке действий, необходимых для чистки зубов" />
                    {/*<ListItem icon={ads_png} title="Отсутвие рекламы" text="Ничего не отвлекает от ухода за собой" />*/}
                    <ListItem icon={support_png} title="Поддержку" text="Поддержка разработчиков этого сервиса" />
                </div>
            </div>
        </Div>

        <div style={{ width: 10, height: 70 }}></div>

        <div className="premium-bottom">
            <FixedLayout vertical="bottom">
                <Div>
                    <Button onClick={() => props.openLink('https://vk.com/donut/teeth_app')} text="Оформить за 99₽" stretched />
                </Div>
            </FixedLayout>
        </div>

        <div className="background--premium">
            <div className="circles"></div>
        </div>
    </Panel>
);

export default Premium;
