import './CommunityBanner.css';
import Button from '../Button/Button';
import banner_png from '../../../img/community.png';
import React from "react";

const CommunityBanner = ({ onClick }) => {
    return (
        <div className="card-community" onClick={onClick}>
            <div className="left">
                <img src={banner_png} alt=""/>
            </div>
            <div className="right">
                <h2>Вступите в наше сообщество</h2>
                <p>Не пропустите ничего важного</p>
                <Button text="Вступить" />
            </div>
        </div>
    );
}

export default CommunityBanner;