import {ModalCard} from "@vkontakte/vkui";
import ModalCardContent from "../components/ModalCardContent/ModalCardContent";
import Button from "../components/Button/Button";

const JoinGroupModal = (props) => {
    return (
        <ModalCard id={props.id}>
            <ModalCardContent
                title="Подпишитесь на наше сообщество"
                text="Чтобы не пропустить никаких новостей и обновлений"
                buttons={
                    <div style={{ marginTop: 21 }}>
                        <Button onClick={() => props.subscribe((result) => {
                            if (result) {
                                props.goBack();
                            }
                        })} text="Подписаться" stretched />
                        <Button onClick={() => props.goBack()} style={{ marginTop: 7 }} text="Отмена" mode="gray" stretched />
                    </div>
                }
            />
        </ModalCard>
    );
}

export default JoinGroupModal;