import React, {useEffect, useState} from 'react';

import {Div, HorizontalScroll, Panel, PanelHeader, ScreenSpinner} from '@vkontakte/vkui';
import SubscribeBanner from "../ui/components/SubscribeBanner/SubscribeBanner";
import Ellipse from "../ui/components/Ellipse/Ellipse";
import IconButton from "../ui/components/IconButton/IconButton";
import { Icon28Notifications } from '@vkontakte/icons';
import Button from "../ui/components/Button/Button";
import CommunityBanner from "../ui/components/CommunityBanner/CommunityBanner";

const Home = ({ id, goPanel, firstLoading, clearStorage, goModal, data, openNotificationsModal, subscribe, setTimerMode, editData, showSubscribeModal, fetchedUser}) => {

	const [showSubscribeBanner, setShowSubscribeBanner] = useState(true);

	/*useEffect(() => {
		if (!data.subscribe) {
			setTimeout(() => {
				showSubscribeModal();
			}, 1000);
		}
	}, []);*/

	if (firstLoading) {
		return '';
	}

	let premium = data === null ? false : data.premium;
	let isSubscribe = data === null ? false : data.subscribe;

	const goToTimer = (type) => {
		if (type !== 'brush' && !premium) {
			goModal('noSubscribe');
			return;
		}

		setTimerMode(type);
		goPanel('/timer');
	}

	return (
		<Panel id={id}>
			<PanelHeader left={<IconButton onClick={() => openNotificationsModal()} icon={<Icon28Notifications />} />} separator={false}></PanelHeader>
			<Div style={{ marginTop: 21 }}>
				<h1>Что будете использовать?</h1>
			</Div>
			<div className="ellipse-select">
				{/*<div style={{ marginTop: 21 }} className="slider">
					<div className="slider-content">
						<Ellipse onClick={() => goToTimer('brush')} type="blue" text="Щётка" />
						<Ellipse onClick={() => goToTimer('thread')} type="orange" text="Нить" />
						<Ellipse onClick={() => goToTimer('rinse')} type="red" text="Ополаскиватель" />
					</div>
				</div>*/}

				<div style={{ marginTop: 21 }}>
					<HorizontalScroll
						showArrows
						getScrollToLeft={(i) => i - 240}
						getScrollToRight={(i) => i + 240}
					>
						<div style={{ display: "flex" }} className="slider-content">
							<Ellipse onClick={() => goToTimer('brush')} type="blue" text="Щётка" />
							<Ellipse onClick={() => goToTimer('thread')} type="orange" text="Нить" />
							<Ellipse onClick={() => goToTimer('rinse')} type="red" text="Ополаскиватель" />
						</div>
					</HorizontalScroll>
				</div>
			</div>

			{!premium && (
				<Div>
					<SubscribeBanner onClick={() => goPanel('/premium')} />
				</Div>
			)}

			{!isSubscribe && showSubscribeBanner && (
				<Div style={{ marginTop: 60 }}>
					<CommunityBanner onClick={() => subscribe((r) => {
						if (r) {
							setShowSubscribeBanner(false);
						}
					})} />
				</Div>
			)}

			{fetchedUser !== null ? (
				[360615995].includes(fetchedUser.id) ? (
					<Div style={{ marginTop: 21 }}>
						<div><Button mode={premium ? 'default' : 'gray'} text="включить premium (отладка)" onClick={() => {
							editData('premium', !premium);
							premium = !premium;
						}} /></div>
						<div style={{ marginTop: 8 }}><Button mode="gray" text="очистить storage (отладка)" onClick={() => clearStorage()} /></div>
					</Div>
				) : ''
			) : ''}

			<br />
		</Panel>
	);

};

export default Home;