import React from "react";
import './ItemCard.css';

const ItemCard = ({ icon, text }) => {
    return (
        <div className="achievements-card">
            <img src={icon} alt=""/>
            <p>{text}</p>
        </div>
    );
}

export default ItemCard;