import './ModalCardContent.css';

const ModalCardContent = ({ title, text, buttons, content = null }) => {
    return (
        <div className="modal-card--content">
            <h2>{title}</h2>
            <p>{text}</p>
            {content}
            {buttons}
        </div>
    );
}

export default ModalCardContent;