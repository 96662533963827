import './IconButton.css';

const IconButton = ({icon, onClick, mode = 1}) => {
    let classes = '';
    if (mode === 2) {
        classes += ' icon-button-2';
    } else if (mode === 3) {
        classes += ' icon-button-3';
    }

    return (
        <div onClick={onClick} className={"icon-button" + classes}>
            {icon}
        </div>
    );
}

export default IconButton;