import './StatisticsCardGrid.css';
import StatisticCard from "../StatisticCard/StatisticCard";
import React from "react";

const StatisticsCardGrid = ({ data }) => {
    if (data === null) {
        return '';
    }

    const days = {
        1: {day: 'Пн.', count: 0},
        2: {day: 'Вт.', count: 0},
        3: {day: 'Ср.', count: 0},
        4: {day: 'Чт.', count: 0},
        5: {day: 'Пт.', count: 0},
        6: {day: 'Сб.', count: 0},
        7: {day: 'Вс.', count: 0},
    };

    for (let i in data) {
        let d = data[i];
        days[d.number] = data[i];
    }

    return (
        <div className="statistics-card-grid">
            <div className="statistics-card-grid--column">
                <StatisticCard animation="down" day={days[1].day} count={days[1].count} />
                <StatisticCard animation="up" day={days[5].day} count={days[5].count} />
            </div>
            <div className="statistics-card-grid--column">
                <StatisticCard animation="down" day={days[2].day} count={days[2].count} />
                <StatisticCard animation="up" day={days[6].day} count={days[6].count} />
            </div>
            <div className="statistics-card-grid--column">
                <StatisticCard animation="down" day={days[3].day} count={days[3].count} />
                <StatisticCard animation="up" day={days[7].day} count={days[7].count} />
            </div>
            <div className="statistics-card-grid--column">
                <StatisticCard animation="down" day={days[4].day} count={days[4].count} />
            </div>
        </div>
    );
}

export default StatisticsCardGrid;