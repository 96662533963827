import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";
import {Page, Router, RouterContext} from "@happysanta/router";
import {
    PAGE_HOME, PAGE_ONBORDING,
    PAGE_PREMIUM,
    PAGE_STATISTICS, PAGE_SUBSCRIBE, PAGE_TIMER,
    PANEL_HOME, PANEL_ONBORDING,
    PANEL_PREMIUM,
    PANEL_STATISTICS, PANEL_SUBSCRIBE, PANEL_TIMER,
    VIEW_MAIN,
    VIEW_STATISTICS
} from "./routes";
import {ConfigProvider} from "@vkontakte/vkui";

import './css/style.css';
import './css/vkui.css';

const routes = {
    [PAGE_HOME]: new Page(PANEL_HOME, VIEW_MAIN),
    [PAGE_PREMIUM]: new Page(PANEL_PREMIUM, VIEW_MAIN),
    [PAGE_ONBORDING]: new Page(PANEL_ONBORDING, VIEW_MAIN),
    [PAGE_SUBSCRIBE]: new Page(PANEL_SUBSCRIBE, VIEW_MAIN),
    [PAGE_TIMER]: new Page(PANEL_TIMER, VIEW_MAIN),

    [PAGE_STATISTICS]: new Page(PANEL_STATISTICS, VIEW_STATISTICS),
};

const router = new Router(routes);
router.start();

// Init VK  Mini App
bridge.send("VKWebAppInit").then();

bridge.subscribe(({ detail: { type, data }}) => {
    if (type === 'VKWebAppUpdateConfig') {
        const schemeAttribute = document.createAttribute('scheme');
        schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
        document.body.attributes.setNamedItem(schemeAttribute);
    }
});

ReactDOM.render((
    <RouterContext.Provider value={router}>
        <ConfigProvider>
            <App />
        </ConfigProvider>
    </RouterContext.Provider>
), document.getElementById("root"));

if (process.env.NODE_ENV === "development") {
    import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
