import {ModalCard} from "@vkontakte/vkui";
import ModalCardContent from "../components/ModalCardContent/ModalCardContent";
import Button from "../components/Button/Button";

const NoSubscribeModal = (props) => {
    return (
        <ModalCard id={props.id}>
            <ModalCardContent
                title="Нужен Premium"
                text="Для использования этой функции нужен Premium-статус."
                buttons={
                    <div style={{ marginTop: 21 }}>
                        <Button onClick={() => props.goPanel('/premium')} text="Подробнее" stretched />
                        <Button onClick={() => props.goBack()} style={{ marginTop: 7 }} text="Отмена" mode="gray" stretched />
                    </div>
                }
            />
        </ModalCard>
    );
}

export default NoSubscribeModal;