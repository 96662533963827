import './Cell.css';
import React from "react";

const Cell = ({ text, right }) => {
    return (
        <div className="cell">
            <p>{text}</p>
            {right}
        </div>
    );
}

export default Cell;