import './TimerPanel.css';

const TimerPanel = ({ text, progress = null }) => {
    return (
        <div className="timer-panel">
            <h1>{text}</h1>
            {progress !== null && (
                <div style={{ marginTop: 35 }}>
                    <div className="progressbar">
                        <div style={{ transform: 'scaleX(' + String(progress) + '%)' }} className="progress"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TimerPanel;