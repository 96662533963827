export const VIEW_MAIN = 'main';
export const VIEW_STATISTICS = 'statistics';


export const PAGE_HOME = '/';
export const PAGE_STATISTICS = '/statistics';
export const PAGE_PREMIUM = '/premium';
export const PAGE_ONBORDING = '/onbording';
export const PAGE_SUBSCRIBE = '/subscribe';
export const PAGE_TIMER = '/timer';


export const PANEL_HOME = 'home';
export const PANEL_STATISTICS = 'statistics';
export const PANEL_PREMIUM = 'premium';
export const PANEL_ONBORDING = 'onbording';
export const PANEL_SUBSCRIBE = 'subscribe';
export const PANEL_TIMER = 'timer';