import {ModalCard} from "@vkontakte/vkui";
import ModalCardContent from "../components/ModalCardContent/ModalCardContent";
import Button from "../components/Button/Button";
import Cell from "../components/Cell/Cell";
import Switch from "../components/Switch/Switch";

const NotificationsModal = (props) => {
    const notifications = props.data.notifications;
    const data = {};

    const change = (name, value) => {
        if (name !== 'news' && !props.data.premium) {
            props.goModal('noSubscribe');
            return;
        }

        //console.log(name + ' ' + value);
        data[name] = value;
    }

    const send = () => {
        props.goBack();
        if (data.length === 0) {
            return;
        }

        const newData = props.data.notifications;
        console.log(newData);
        for (let i in data) {
            newData[i] = data[i];
        }
        props.editData('notifications', newData);

        props.serverPost('editeNotifications', {notifications: JSON.stringify(data)}, (response) => {
            console.log(response);
        });

        props.ads(() => {});
    }

    return (
        <ModalCard id={props.id}>
            <ModalCardContent
                title="Уведомления"
                text="Уведомления какого характера вы хотите получать?"
                content={
                    <div className="cell-list">
                        <Cell text="Чистка зубов" right={<Switch onClick={(value) => change('cleaning', value)} isChecked={notifications.cleaning} />} />
                        <Cell text="Замена щетки" right={<Switch onClick={(value) => change('replacement', value)} isChecked={notifications.replacement} />} />
                        <Cell text="Посещение стоматолога" right={<Switch onClick={(value) => change('visit', value)} isChecked={notifications.visit} />} />
                        <Cell text="Новости сервиса" right={<Switch onClick={(value) => change('news', value)} isChecked={notifications.news} />} />
                    </div>
                }
                buttons={
                    <div style={{ marginTop: 21 }}>
                        <Button onClick={() => send()} text="Готово" stretched />
                        <Button onClick={() => props.goBack()} style={{ marginTop: 7 }} text="Отмена" mode="gray" stretched />
                    </div>
                }
            />
        </ModalCard>
    );
}

export default NotificationsModal;