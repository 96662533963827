import './SubscribeBanner.css';
import Button from '../Button/Button';
import premium_banner_png from '../../../img/premium-banner.png';
import React from "react";

const SubscribeBanner = ({ onClick }) => {
    return (
        <div className="card" onClick={onClick}>
            <div className="left">
                <img src={premium_banner_png} alt=""/>
            </div>
            <div className="right">
                <h2>Не упустите шанс!</h2>
                <p>Подписка со скидкой 50%</p>
                <Button text="Подробнее" />
            </div>
        </div>
    );
}

export default SubscribeBanner;